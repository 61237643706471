<template>
	<div class="flex-direction-column">
		<img v-if="banner" class="pic" :src="banner" alt=""/>
		<div class="wrap">
			<div class="title title-vertical flex-space-between">
				<span>站内搜索</span>
			</div>
			<List :isSearch="1"></List>
		</div>
	</div>
</template>

<script>
import {getBanner} from '@/service/api/index'
import List from '@/components/list/list.vue'

export default {
	components: {List},
	data() {
		return {
			banner: ''
		}
	},

	mounted() {
		this.getBanner()
	},

	methods: {
		getBanner() {
			getBanner({id: 0}).then((res) => {
				this.banner = res.msg
			})
		}
	}
}
</script>

<style lang="less" scoped>
.pic {
	width: 100%;
	height: 283px;
	object-fit: cover;
	margin-top: 150px;
}

.wrap {
	width: 1360px;
	margin: 20px auto;
	padding: 30px 0;
	border-radius: 6px;
	background-color: #fff;
}

.title {
	width: 1300px;
}
</style>
